import { ref } from 'vue'

const modalOpenChangeEventName = 'modal-open-change'

export const useAuthenticationStore = defineStore('authentication', () => {
    const supabaseClient = useSupabaseClient()
    const session = useSupabaseSession()
    const isAuthenticated = ref<boolean>(
        !!session.value && !session.value.user.is_anonymous
    )
    const modalOpen = ref(false)

    watch(session, (newVal) => {
        if (newVal && !newVal.user.is_anonymous) {
            isAuthenticated.value = true
            modalOpen.value = false
        }
    })

    function requireAuth(): Promise<{ isAuthenticated: boolean }> {
        return new Promise((resolve) => {
            modalOpen.value = true
            document.addEventListener(modalOpenChangeEventName, (e: any) => {
                if (e.detail === false) {
                    if (isAuthenticated.value) {
                        resolve({ isAuthenticated: true })
                    } else {
                        resolve({ isAuthenticated: false })
                    }
                }
            })
        })
    }

    watch(modalOpen, (newVal) => {
        document.dispatchEvent(
            new CustomEvent(modalOpenChangeEventName, { detail: newVal })
        )
    })

    async function signOut() {
        const res = await supabaseClient.auth.signOut()

        window.location.href = Urls.Home()
    }
    return {
        isAuthenticated,
        signOut,
        requireAuth,
        modalOpen,
    }
})
