// Nuxt 3 app aliases

export default defineNuxtPlugin((nuxtApp) => {
    const rngSeedState = useState<string>('rngSeed', () => 'unset')
    if (process.server) {
        const event = useRequestEvent()
        nuxtApp.provide('rngSeed', event?.context.rngSeed)

        nuxtApp.hooks.hook('app:rendered', () => {
            rngSeedState.value = event?.context.rngSeed
        })
    }
    if (process.client) {
        // const rngSeed = useCookie('rngSeed')
        nuxtApp.provide('rngSeed', rngSeedState.value)
    }
})
