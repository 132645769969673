<template>
    <div class="size-full">
        <Head>
            <Link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon.png"
                media="(prefers-color-scheme: light)"
            />
            <Link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32.png"
                media="(prefers-color-scheme: light)"
            />
            <Link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16.png"
                media="(prefers-color-scheme: light)"
            />
            <Link
                rel="apple-touch-icon"
                sizes="180x180"
                href="/apple-touch-icon-dark-mode.png"
                media="(prefers-color-scheme: dark)"
            />
            <Link
                rel="icon"
                type="image/png"
                sizes="32x32"
                href="/favicon-32x32-dark-mode.png"
                media="(prefers-color-scheme: dark)"
            />
            <Link
                rel="icon"
                type="image/png"
                sizes="16x16"
                href="/favicon-16x16-dark-mode.png"
                media="(prefers-color-scheme: dark)"
            />
            <Link rel="manifest" href="/site.webmanifest" />
            <Link
                rel="mask-icon"
                href="/safari-pinned-tab.svg"
                color="#000000"
            />
            <meta name="msapplication-TileColor" content="#da532c" />
            <Meta name="theme-color" content="#ffffff" />
        </Head>
        <NuxtLoadingIndicator :color="progressColor" />
        <NuxtLayout>
            <NuxtPage />

            <LazyAuthenticationModal />
            <LazyTripCreationModal />

            <ConfirmationDialog />

            <Toaster position="top-center" />
            <!-- <VueQueryDevtools v-if="dev" /> -->
        </NuxtLayout>
    </div>
</template>

<script setup lang="ts">
import { Toaster } from 'vue-sonner'

const dev = import.meta.dev

const config = useRuntimeConfig()
const weights = [300, 350, 400, 450, 500, 550, 600, 650, 750, 800]

useHead({
    titleTemplate: (titleChunk) => {
        return titleChunk
            ? `${titleChunk} | ${config.public.appName}`
            : `${config.public.appName}`
    },
    link: weights.map((weight) => ({
        rel: 'preload',
        href: `/fonts/TWKLausanne-${weight}.woff2`,
        as: 'font',
        type: 'font/woff2',
        crossorigin: 'anonymous',
    })),
})

const route = useRoute()
const router = useRouter()

// https://github.com/vitejs/vite/issues/11804#issuecomment-1406182566
router.onError((error, to) => {
    if (error.message.includes('Failed to fetch dynamically imported module')) {
        if (window) {
            // @ts-ignore
            window.location = to.fullPath
        }
    }
})

const progressColor = computed(() => {
    switch (route.path) {
        case '/':
            return '#FFFFFF'
        default:
            return '#000000'
    }
})
</script>
