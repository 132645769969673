<template>
    <Modal
        v-model="isModalVisible"
        :title="'Confirm'"
        class="relative z-dialog"
        panel-class="pb-page-gutter"
        @update:model-value="
            () => {
                if (!$event) {
                    handleCancel()
                }
            }
        "
    >
        <div class="flex w-full max-w-[260px] flex-col gap-4">
            <div class="text-center">{{ content?.title }}</div>
            <AButton
                :variant="content?.destructive ? `destructive` : `primary`"
                @click="handleConfirm"
            >
                {{ content?.confirmText }}
            </AButton>

            <AButton variant="secondary" @click="handleCancel">
                Cancel
            </AButton>
        </div>
    </Modal>
</template>

<script setup lang="ts">
import Modal from '~/components/Modal.vue'

const { isModalVisible, content } = useConfirmationDialog()

const handleCancel = () => {
    window.dispatchEvent(ConfirmationDialogCancelEvent)
}

const handleConfirm = () => {
    window.dispatchEvent(ConfirmationDialogConfirmEvent)
}
</script>
