<template>
    <header
        :class="[
            `h-header transition-opacity-bg fixed inset-0 z-50 flex w-full flex-row items-center justify-center`,
            bgStyle,
        ]"
    >
        <div
            class="max-w-page px-page-gutter flex h-fit w-full flex-row items-center justify-between gap-8 text-neutral-50"
        >
            <NuxtLink
                key="link"
                :href="Urls.Home()"
                class="flex-00auto flex items-center justify-start"
            >
                <IconLogo
                    :class="[
                        `h-logo w-auto`,
                        logoStyle === 'light'
                            ? 'text-neutral-50'
                            : 'text-neutral-1000',
                    ]"
                />
            </NuxtLink>

            <!-- <div
                v-if="route.path !== '/auth'"
                class="flex flex-row items-center gap-6"
            >
                <div class="hidden flex-row gap-6 md:flex">
                    <NuxtLink
                        :to="Urls.Enterprise()"
                        class="font-500 text-lg text-neutral-950"
                        >For Companies</NuxtLink
                    >
                    <NuxtLink
                        :to="Urls.MagicFinder()"
                        class="font-500 text-lg text-neutral-950"
                        >Ascend MagicFinder™</NuxtLink
                    >
                </div>
                <div v-if="!isAuthenticated">
                    <button
                        :class="[
                            `rounded-full px-4 py-1.5 font-500 text-sm text-neutral-950 lg:text-lg`,
                        ]"
                        @click="authStore.requireAuth"
                    >
                        Login
                    </button>
                </div>
                <div v-else>
                    <Popover class="relative">
                        <AFloat placement="bottom-end" portal>
                            <PopoverButton
                                :id="buttonId"
                                class="flex flex-row items-center justify-between gap-2 pl-2 pr-0.5"
                            >
                                <div
                                    class="flex size-8 items-center justify-center rounded-full bg-neutral-200"
                                >
                                    <IconUserRounded
                                        class="size-5 text-neutral-500"
                                    />
                                </div>
                                <IconCaretUp
                                    :class="[
                                        `size-3 rotate-180 text-neutral-700 transition-transform duration-200 ease-in-out ui-open:rotate-0`,
                                    ]"
                                />
                            </PopoverButton>

                            <PopoverPanel
                                v-slot="{ close }"
                                class="w-72 rounded-2xl border border-neutral-200 bg-neutral-50 p-2 shadow-menu"
                            >
                                <NuxtLink
                                    :href="Urls.Account()"
                                    class="block w-full rounded-lg p-3 text-neutral-800 hover:bg-neutral-100 hover:text-neutral-900"
                                    @click="close"
                                    >Account</NuxtLink
                                >
                                <NuxtLink
                                    :href="Urls.Trips()"
                                    class="block w-full rounded-lg p-3 text-neutral-800 hover:bg-neutral-100 hover:text-neutral-900"
                                    @click="close"
                                    >Trips</NuxtLink
                                >
                                <NuxtLink
                                    :href="Urls.ContactUs()"
                                    class="block w-full rounded-lg p-3 text-neutral-800 hover:bg-neutral-100 hover:text-neutral-900"
                                    @click="close"
                                    >Contact Us</NuxtLink
                                >
                                <NuxtLink
                                    :href="Urls.MagicFinder()"
                                    class="block w-full rounded-lg p-3 text-neutral-800 hover:bg-neutral-100 hover:text-neutral-900"
                                    @click="close"
                                    >Ascend MagicFinder™
                                </NuxtLink>
                                <NuxtLink
                                    :href="Urls.Enterprise()"
                                    class="block w-full rounded-lg p-3 text-neutral-800 hover:bg-neutral-100 hover:text-neutral-900"
                                    @click="close"
                                    >Ascend Enterprise
                                </NuxtLink>
                                <button
                                    class="block w-full rounded-lg p-3 text-left text-neutral-900 hover:bg-neutral-100"
                                    @click="
                                        () => {
                                            close()
                                            authStore.signOut()
                                        }
                                    "
                                >
                                    Log Out
                                </button>
                            </PopoverPanel>
                        </AFloat>
                    </Popover>
                </div> -->
            <!-- </div> -->
        </div>
    </header>
</template>
<script setup lang="ts">
import IconLogo from '~/components/Icon/IconLogo.vue'
import { Urls } from '~/utils/urls'

const buttonId = useId()

const authStore = useAuthenticationStore()
const { isAuthenticated } = storeToRefs(authStore)

const route = useRoute()

const bodyScrollStore = useBodyScrollStore()
const { isOnTop } = storeToRefs(bodyScrollStore)

const logoStyle = computed(() => {
    switch (route.path) {
        // case '/':
        //     return 'light'
        default:
            return 'dark'
    }
})

const bgStyle = computed(() => {
    switch (route.path) {
        // case '/':
        //     return !isOnTop.value
        //         ? `bg-neutral-1000/40 backdrop-blur`
        //         : 'bg-transparent'
        default:
            return 'bg-neutral-100/90 backdrop-blur'
    }
})
</script>
