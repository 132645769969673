import type {
    DehydratedState,
    VueQueryPluginOptions,
} from '@tanstack/vue-query'
import {
    dehydrate,
    hydrate,
    MutationCache,
    QueryCache,
    QueryClient,
    VueQueryPlugin,
} from '@tanstack/vue-query'
import debounce from 'lodash.debounce'
import { toast } from 'vue-sonner'

// Nuxt 3 app aliases
import { useState } from '#app'

export default defineNuxtPlugin((nuxt) => {
    const vueQueryState = useState<DehydratedState | null>('vue-query')

    const showError = debounce(
        (errorMessage: string) => {
            toast.error(errorMessage)
        },
        1000,
        {
            leading: true,
            trailing: true,
        }
    )

    // Modify your Vue Query global settings here
    const queryClient = new QueryClient({
        defaultOptions: {
            queries: { staleTime: Infinity, retry: false },
        },
        queryCache: new QueryCache({
            onError: (error, query) => {
                if (query.meta?.errorMessage) {
                    showError(query.meta.errorMessage as string)
                }
            },
        }),
        mutationCache: new MutationCache({
            onError: (error, variables, context, mutation) => {
                if (mutation.meta?.errorMessage) {
                    showError(mutation.meta.errorMessage as string)
                }
            },
        }),
    })
    const options: VueQueryPluginOptions = { queryClient }

    nuxt.vueApp.use(VueQueryPlugin, options)

    if (process.server) {
        nuxt.hooks.hook('app:rendered', () => {
            vueQueryState.value = dehydrate(queryClient)
        })
    }

    if (process.client) {
        nuxt.hooks.hook('app:created', () => {
            hydrate(queryClient, vueQueryState.value)
        })
    }
})
