<template>
    <button
        :class="[
            `group relative h-button  overflow-hidden rounded-full px-6  outline-offset-[3px] transition-transform`,
            props.variant === 'primary'
                ? 'bg-neutral-1000 text-neutral-50 outline-neutral-1000  hover:bg-neutral-700  active:bg-neutral-800'
                : '',
            props.variant === 'seethrough'
                ? 'bg-neutral-50/10 text-neutral-50 outline-neutral-50/10  hover:bg-neutral-50/30  active:bg-neutral-50/20'
                : '',
            props.variant === 'secondary'
                ? ' bg-neutral-50 text-neutral-1000 outline-neutral-700 ring-1 ring-inset ring-neutral-200 hover:bg-neutral-100'
                : '',
            props.variant === 'destructive'
                ? 'bg-red-400/85 text-neutral-50 outline-red-400/85 hover:bg-red-400/75 active:bg-red-400/80'
                : '',
            buttonDisabled
                ? `cursor-default !bg-neutral-300 text-neutral-1000/15 `
                : `cursor-pointer active:scale-99`,
            buttonClass ? buttonClass : '',
        ]"
        :disabled="buttonDisabled"
        v-bind="slimmedAttrs"
    >
        <div
            :class="[
                'relative flex size-full flex-row items-center justify-center gap-3 transition-opacity',
                props.loading ? 'opacity-0' : 'opacity-100',
                innerClass,
            ]"
        >
            <slot />
            <component :is="icon" :class="[`size-4`, iconClass]" />
        </div>
        <div
            :class="[
                'absolute inset-0 flex size-full items-center justify-center',
                props.loading ? 'opacity-100' : 'opacity-0',
            ]"
        >
            <Spinner
                :class="[
                    buttonDisabled ? `text-neutral-1000/50` : ``,
                    spinnerClass,
                ]"
            />
        </div>
    </button>
</template>

<script setup lang="ts">
import { type ButtonHTMLAttributes, type Component } from 'vue'

import Spinner from '~/components/Spinner.vue'

interface ButtonProps extends /** @vue-ignore */ ButtonHTMLAttributes {
    icon?: Component
    iconClass?: string
    innerClass?: string
    spinnerClass?: string
    loading?: boolean
    variant?: 'primary' | 'seethrough' | 'secondary' | 'destructive'
    buttonClass?: string
}

const props = withDefaults(defineProps<ButtonProps>(), {
    loading: false,
    variant: 'primary',
})

defineOptions({
    inheritAttrs: false,
})

const attrs = useAttrs()

const disabledViaAttr = computed(() => {
    if (attrs['disabled'] !== undefined) {
        return !!attrs['disabled']
    } else {
        return false
    }
})

const slimmedAttrs = Object.fromEntries(
    Object.entries(attrs).filter(([key]) => key !== 'disabled')
)

const buttonDisabled = computed(() => {
    return !!props.loading || !!disabledViaAttr.value
})
</script>
