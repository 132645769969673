export const useBodyScrollStore = defineStore('useBodyScroll', () => {
    const yBodyScroll = ref<number>()
    const isOnTop = ref<boolean>()
    const isOnBottom = ref<boolean>()

    function updateScroll() {
        yBodyScroll.value = window.scrollY

        if (yBodyScroll.value === 0) {
            isOnTop.value = true
        } else {
            isOnTop.value = false
        }

        if (
            yBodyScroll.value + window.innerHeight ===
            document.body.scrollHeight
        ) {
            isOnBottom.value = true
        } else {
            isOnBottom.value = false
        }
    }

    onMounted(() => {
        window.addEventListener('scroll', updateScroll)
        window.addEventListener('resize', updateScroll)
        updateScroll()
    })

    onUnmounted(() => {
        window.removeEventListener('scroll', updateScroll)
        window.removeEventListener('resize', updateScroll)
    })

    return {
        yBodyScroll,
        isOnTop,
        isOnBottom,
    }
})
