import * as amplitude from '@amplitude/analytics-browser'

import { AnalyticsEvent } from '~/utils/analytics'

/**
 * **IMPORTANT**
 * This properties names are used by GTM variables in order to track the events,
 * - if these names change the variables in GTM must be updated as well,
 * - if u add new properties to this object, make sure to add them to the GTM variables as well
 * along side the triggers and tags to send the data to GA4
 */
type EventProperties = {
    dealId?: string
    dealPrice?: number
    dealPriceCurrency?: string
    hotelBookingReference?: string
    tripId?: string
    userId?: string
}

export default defineNuxtPlugin((_nuxtApp) => {
    const config = useRuntimeConfig()
    if (!import.meta.dev) {
        amplitude.init(config.public.amplitudeKey, {
            defaultTracking: {
                pageViews: true,
                sessions: true,
            },
        })
    }

    const user = useSupabaseUser()
    const gtm = useGtm() // auto-imported by the module

    watch(
        user,
        (newVal) => {
            if (newVal) {
                amplitude.setUserId(newVal.id)
            } else {
                amplitude.setUserId(undefined)
            }
        },
        { immediate: true }
    )

    return {
        provide: {
            trackEvent: ({
                eventName,

                eventProperties = {},
            }: {
                eventName: AnalyticsEvent
                eventProperties?: EventProperties
            }) => {
                gtm!.trackEvent({
                    event: eventName,
                    ...(!!user.value && { user_id: user.value.id }),
                    ...(!!eventProperties && eventProperties),
                })
                amplitude.track(eventName, eventProperties)
                console.log('Tracked event:', eventName, eventProperties)
            },
        },
    }
})
