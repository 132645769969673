import * as Sentry from '@sentry/vue'

export default defineNuxtPlugin((nuxtApp) => {
    const router = useRouter()
    const {
        public: { sentry, apiHost },
    } = useRuntimeConfig()

    Sentry.init({
        app: nuxtApp.vueApp,
        dsn: sentry.dsn,
        enabled: !import.meta.dev,
        environment: sentry.environment,
        integrations: [
            Sentry.browserTracingIntegration({ router }),
            Sentry.replayIntegration({
                maskAllText: false,
                blockAllMedia: false,
            }),
            Sentry.breadcrumbsIntegration({
                console: true,
                dom: true,
                fetch: true,
                history: true,
                xhr: true,
            }),
        ],

        ignoreErrors: [
            'fetch failed',
            'Load failed',
            'network error',
            'Failed to fetch',
            'No error message',
            'Failed to fetch dynamically imported module',
        ],

        // Configure this whole part as you need it!

        tracesSampleRate: 0.2, // Change in prod

        // Set `tracePropagationTargets` to control for which URLs distributed tracing should be enabled
        tracePropagationTargets: [apiHost],

        // Capture Replay for 10% of all sessions,
        // plus for 100% of sessions with an error
        replaysSessionSampleRate: 0.1,
        replaysOnErrorSampleRate: 1.0,
    })

    /**
     * Set the sentry user
     */
    const user = useSupabaseUser()
    watch(
        user,
        (newVal) => {
            if (newVal) {
                Sentry.setUser({
                    id: newVal.id,
                    email: newVal.email || newVal.phone,
                })
            } else {
                Sentry.setUser(null)
            }
        },
        { immediate: true }
    )
})
