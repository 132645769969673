class CustomEvent extends Event {
    detail: any
    constructor(message: string, data: any) {
        super(message, data)
        this.detail = data.detail
    }
}

enum Response {
    Cancel = 'cancel',
    Confirm = 'confirm',
}

export const ConfirmationDialogConfirmEvent = new CustomEvent(
    'confirmationDialogResponse',
    { detail: Response.Confirm }
)

export const ConfirmationDialogCancelEvent = new CustomEvent(
    'confirmationDialogResponse',
    { detail: Response.Cancel }
)

const isModalVisible = ref(false)
const content = ref<ConfirmationDialogContent | null>(null)

interface ConfirmationDialogContent {
    title: string
    confirmText: string
    destructive: boolean
}

export function useConfirmationDialog() {
    async function confirm(
        newContent: ConfirmationDialogContent
    ): Promise<boolean> {
        content.value = newContent
        isModalVisible.value = true

        return new Promise((resolve) => {
            ;(window as any).addEventListener(
                'confirmationDialogResponse',
                (
                    evt:
                        | typeof ConfirmationDialogCancelEvent
                        | typeof ConfirmationDialogConfirmEvent
                ) => {
                    isModalVisible.value = false

                    resolve(evt.detail === Response.Confirm)
                }
            )
        })
    }

    return { confirm, isModalVisible, content }
}
