<template>
    <TransitionRoot :show="visible" as="template">
        <Dialog
            class="relative z-dialog"
            @close="
                () => {
                    visible = false
                }
            "
        >
            <TransitionChild
                enter="duration-300 ease-out"
                enter-from="opacity-0"
                enter-to="opacity-100"
                leave="duration-200 ease-in"
                leave-from="opacity-100"
                leave-to="opacity-0"
                class="fixed inset-0 hidden bg-neutral-1000/30 backdrop-blur-sm sm:block"
            />

            <div
                class="fixed inset-0 flex w-screen items-center justify-center"
            >
                <TransitionChild
                    enter="duration-300 ease-out"
                    enter-from="opacity-0 translate-y-2 "
                    enter-to="opacity-100 translate-y-0 "
                    leave="duration-200 ease-in"
                    leave-from="opacity-100 "
                    leave-to="opacity-0 translate-y-2 "
                    as="template"
                >
                    <DialogPanel
                        :class="[
                            `flex size-full flex-col items-center  bg-neutral-50`,
                            `overflow-hidden shadow-menu sm:h-auto sm:max-w-dialogContent sm:rounded-2xl`,
                            panelClass,
                        ]"
                    >
                        <button
                            v-if="showTopRightCloseButton"
                            class="absolute right-6 top-6 rounded-full outline-offset-2 outline-neutral-300"
                            @click="visible = false"
                        >
                            <IconXCircle class="size-6 text-neutral-300" />
                        </button>
                        <DialogTitle
                            v-if="!!title"
                            class="relative w-full bg-neutral-50 py-6 text-center font-600 text-xl text-neutral-1000 shadow-[0px_-4px_12px_0px_rgba(0,0,0,0.05)]"
                        >
                            <div
                                class="absolute inset-y-0 left-page-gutter flex items-center justify-center"
                            >
                                <button
                                    class="rounded-full outline-offset-2 outline-neutral-300"
                                    @click="visible = false"
                                >
                                    <IconXCircle
                                        class="size-6 text-neutral-300"
                                    />
                                </button>
                            </div>
                            {{ title }}
                        </DialogTitle>

                        <slot
                            :trigger-close="
                                () => {
                                    visible = false
                                }
                            "
                        />
                    </DialogPanel>
                </TransitionChild>
            </div>
        </Dialog>
    </TransitionRoot>
</template>

<script setup lang="ts">
import {
    Dialog,
    DialogPanel,
    DialogTitle,
    TransitionChild,
    TransitionRoot,
} from '@headlessui/vue'

const visible = defineModel<boolean>({ default: false })

defineProps<{
    title?: string
    panelClass?: string
    showTopRightCloseButton?: boolean
}>()
</script>
