<template>
    <Page>
        <Header />

        <div
            class="mt-page-gutter flex w-full max-w-page flex-col items-start justify-center gap-6"
        >
            <div v-if="error?.statusCode" class="font-mono text-xl">
                {{ error.statusCode }}
            </div>
            <h2 class="font-400 text-xl">🙁 {{ message }}</h2>
            <button class="text-neutral-1000/50 underline" @click="handleError">
                Return Home
            </button>
            <div
                v-if="dev"
                class="flex w-full flex-col items-stretch gap-4 border border-neutral-200 bg-neutral-50 px-3 py-2 font-mono text-xs"
            >
                <div>{{ error?.message || 'Unknown Error' }}</div>
                <div
                    v-if="error?.stack"
                    class="no-scrollbar w-full overflow-scroll rounded-xl"
                    v-html="error?.stack"
                />
            </div>
        </div>
    </Page>
</template>

<script setup lang="ts">
import type { NuxtError } from '#app'
import Page from '~/components/Page.vue'

const props = defineProps({
    error: Object as () => NuxtError,
})

const handleError = () => clearError({ redirect: '/' })

const message = computed(() => {
    switch (props.error?.statusCode) {
        case 403:
        case 401:
            return `You don't have access to this page`
        case 404:
            return `Page not found`
        default:
            return `Whoops, something went wrong, we're working on fixing it!`
    }
})

const dev = import.meta.dev
</script>
