<template>
    <svg
        width="100%"
        height="100%"
        viewBox="0 0 97 31"
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
    >
        <path
            d="M6.21134 11.4031C5.96871 11.7159 4.87123 14.0524 4.87123 14.006C4.71986 13.9911 5.93231 14.006 8.24943 14.006L11.6463 14.0686C11.6463 14.0936 11.5856 14.2688 11.5007 14.444L11.3672 14.7569H6.41757H1.48005L0.740023 16.0833C0.327551 16.8092 0 17.51 0 17.51C0 17.51 2.67668 17.51 5.74596 17.51H11.161L11.44 17.0469C12.1437 15.8831 14.5578 11.5032 14.5578 11.3906L6.21134 11.4031Z"
            fill="currentColor"
        />
        <path
            d="M89.7091 4.76728C91.9121 4.76728 93.234 6.16251 93.6256 7.21506H93.6501V0.287842H96.8811V17.765H93.6256V15.7823H93.6011C93.136 16.7614 91.8877 18.0832 89.7581 18.0832C86.3312 18.0832 83.7855 15.4886 83.7855 11.4252C83.7855 7.36193 86.3067 4.76728 89.7091 4.76728ZM86.9187 11.4252C86.9187 13.7506 88.1181 15.6109 90.419 15.6109C92.7934 15.6109 93.9193 13.5793 93.9193 11.4252C93.9193 9.27119 92.7199 7.26401 90.419 7.26401C88.167 7.26401 86.9187 9.09985 86.9187 11.4252Z"
            fill="currentColor"
        />
        <path
            d="M71.0323 17.765V5.11002H74.1166V7.28855C74.8264 5.81988 75.9524 4.76733 77.9106 4.76733C81.1906 4.76733 82.4635 6.92138 82.4635 10.0301V17.765H79.3303V10.7889C79.3303 8.95304 78.8408 7.50885 77.0294 7.50885C75.3404 7.50885 74.19 9.00199 74.19 12.0862V17.765H71.0323Z"
            fill="currentColor"
        />
        <path
            d="M69.3166 14.0445C68.5578 16.5657 66.3548 18.1568 63.2951 18.1568C59.4766 18.1568 56.8085 15.4397 56.8085 11.4009C56.8085 7.53339 59.55 4.74292 63.3196 4.74292C67.5542 4.74292 69.488 7.94951 69.5124 11.6946V12.3066H59.8192C60.064 14.3382 61.4348 15.6111 63.2706 15.6111C64.5924 15.6111 65.7184 15.146 66.1835 14.0445H69.3166ZM59.8437 10.2015H66.4772C66.2814 8.56146 65.3023 7.14174 63.2706 7.14174C61.3858 7.14174 60.1375 8.36563 59.8437 10.2015Z"
            fill="currentColor"
        />
        <path
            d="M55.9993 13.5304C55.4608 16.4922 53.1109 18.1322 50.0757 18.1322C46.3551 18.1322 43.3198 15.562 43.3198 11.4253C43.3198 7.28855 46.3551 4.76733 50.0757 4.76733C53.1599 4.76733 55.4853 6.50526 55.9504 9.24677H52.9641C52.548 7.94945 51.5199 7.23959 50.0512 7.23959C47.8237 7.23959 46.453 9.07543 46.453 11.4498C46.453 13.7996 47.8237 15.66 50.0512 15.66C51.4709 15.66 52.6214 14.9746 53.0375 13.5304H55.9993Z"
            fill="currentColor"
        />
        <path
            d="M39.4751 8.87949C39.2793 7.60664 38.3001 7.06813 37.1986 7.06813C36.1461 7.06813 35.2649 7.63112 35.2649 8.46336C35.2649 9.29561 35.9503 9.73621 37.321 10.1034C37.9085 10.2747 38.447 10.3971 39.0345 10.544C41.1885 11.107 42.6082 12.1595 42.6082 14.1422C42.6082 16.5655 40.3073 18.1566 37.321 18.1566C34.5795 18.1566 32.2297 16.8837 31.887 13.8485H34.9712C35.1915 15.1458 35.9992 15.7822 37.37 15.7822C38.5449 15.7822 39.524 15.2927 39.524 14.2646C39.524 13.261 38.5694 12.9673 37.6148 12.7225C37.0028 12.5267 36.3909 12.3798 35.7545 12.2085C33.6983 11.67 32.3031 10.4705 32.3031 8.51232C32.3031 6.26036 34.4571 4.71826 37.2231 4.71826C40.1115 4.71826 42.0942 6.18693 42.4124 8.87949H39.4751Z"
            fill="currentColor"
        />
        <path
            d="M19.7292 8.9774C20.0964 6.89679 21.8343 4.71826 25.1633 4.71826C29.1287 4.71826 30.5974 6.9947 30.5974 10.1034V14.6807C30.5974 15.6598 30.6708 16.9572 30.8422 17.7649H27.6845C27.6111 17.3488 27.5866 16.8837 27.5866 16.4676V15.8312H27.5621C27.0481 16.8348 25.9956 18.1076 23.5967 18.1076C20.8307 18.1076 19.1663 16.4431 19.1663 14.3136C19.1663 10.8377 23.3275 10.495 25.0899 10.2258C26.7299 9.98099 27.5132 9.73621 27.5132 8.65919C27.5132 7.6556 26.632 7.06813 25.1878 7.06813C23.9394 7.06813 23.0093 7.80247 22.6911 8.9774H19.7292ZM22.2994 14.1667C22.2994 15.0724 23.0338 15.7822 24.4535 15.7822C26.069 15.7822 27.5621 14.8276 27.5621 11.8903V10.8132C27.2684 11.4252 26.6075 11.9882 24.7472 12.3553C23.0582 12.698 22.2994 13.2121 22.2994 14.1667Z"
            fill="currentColor"
        />
        <path
            d="M56.736 25.5409C56.0515 25.7292 55.3213 26.1999 54.9563 26.702C54.6977 27.0629 54.6368 27.3768 54.6368 28.2241C54.6368 29.495 54.8954 29.9658 55.945 30.5777C56.5382 30.9229 56.7816 30.97 58.2115 30.97C59.6413 30.97 59.8847 30.9229 60.4779 30.5777C61.5275 29.9658 61.7861 29.495 61.7861 28.2241C61.7861 26.9531 61.5275 26.4824 60.5084 25.8861C59.9912 25.588 59.5805 25.5095 58.5309 25.4625C57.816 25.4311 57.0098 25.4625 56.736 25.5409ZM59.6413 27.0002C60.0216 27.1728 60.341 27.722 60.341 28.2241C60.341 29.1969 59.5957 29.7147 58.2115 29.7147C57.1771 29.7147 56.4774 29.4166 56.2492 28.9145C55.6408 27.5337 56.6904 26.5608 58.5613 26.7491C58.9568 26.7962 59.4436 26.906 59.6413 27.0002Z"
            fill="currentColor"
        />
        <path
            d="M47.9742 25.7762C46.8485 26.1999 46.3466 26.9687 46.3466 28.2711C46.3466 29.2753 46.742 29.9971 47.5635 30.4835C48.035 30.7816 48.4001 30.813 50.7274 30.8601L53.3589 30.9229L53.3133 29.291L53.2677 27.6748L51.3358 27.6278L49.3888 27.5807V28.2083V28.836L50.6513 28.883C51.777 28.9301 51.8986 28.9615 51.8986 29.2439C51.8986 29.5264 51.777 29.5577 50.5296 29.6048C49.7843 29.6362 49.0085 29.6048 48.8108 29.5577C48.2632 29.4165 47.8677 28.8987 47.8677 28.3182C47.8677 26.9844 48.2632 26.8118 51.1381 26.8118H53.3437V26.1842V25.5566H50.9556C49.328 25.5723 48.3696 25.635 47.9742 25.7762Z"
            fill="currentColor"
        />
        <path
            d="M63.0791 28.2241V30.8916H63.9157H64.7523V30.0286V29.1656H65.6346H66.5016L67.1557 30.0286C67.8098 30.8916 67.825 30.8916 68.6464 30.8916C69.1027 30.8916 69.4678 30.8759 69.4678 30.8445C69.4678 30.8131 69.194 30.4209 68.8594 29.9501L68.2509 29.1185L68.692 28.8361C68.9354 28.6949 69.2396 28.3967 69.3765 28.2085C69.6808 27.7377 69.696 26.8277 69.3765 26.3726C68.8898 25.6508 68.4487 25.5567 65.665 25.5567H63.0791V28.2241ZM68.0227 27.3611V27.8319L66.3951 27.8789L64.7523 27.926V27.3611V26.7963L66.3951 26.8434L68.0227 26.8904V27.3611Z"
            fill="currentColor"
        />
        <path
            d="M70.8368 28.224V30.9229L73.4683 30.8601C75.75 30.813 76.1455 30.7659 76.6018 30.4992C77.4993 29.95 77.7579 29.4479 77.7579 28.224C77.7579 27.0001 77.4993 26.498 76.6018 25.9488C76.1455 25.6821 75.75 25.635 73.4683 25.5879L70.8368 25.5252V28.224ZM75.8717 27.2669C76.1759 27.5807 76.3128 27.8788 76.3128 28.224C76.3128 28.5692 76.1759 28.8673 75.8717 29.1812C75.4458 29.6205 75.3697 29.6362 73.9703 29.6362H72.51V28.224V26.8118H73.9703C75.3697 26.8118 75.4458 26.8275 75.8717 27.2669Z"
            fill="currentColor"
        />
        <path
            d="M79.0508 28.2241V30.8916H79.8113H80.5719V28.2241V25.5567H79.8113H79.0508V28.2241Z"
            fill="currentColor"
        />
        <path
            d="M82.9904 28.1928L81.5149 30.8131L82.2907 30.8602C83.0056 30.9073 83.1121 30.8759 83.4619 30.4366C83.8422 29.9501 83.8422 29.9501 85.409 29.9501H86.9757L87.2343 30.4209C87.4777 30.8602 87.5537 30.8916 88.3751 30.8916H89.2726L88.9836 30.3738C88.8315 30.1071 88.147 28.8989 87.4929 27.7063L86.2912 25.5567H85.3633H84.4507L82.9904 28.1928ZM85.8805 27.8319C86.1391 28.3026 86.3521 28.7263 86.3521 28.7733C86.3521 28.8204 85.9109 28.8518 85.3633 28.8518C84.8157 28.8518 84.3746 28.8047 84.3746 28.7263C84.3746 28.585 85.2721 26.9689 85.3481 26.9689C85.3785 26.9689 85.6219 27.3611 85.8805 27.8319Z"
            fill="currentColor"
        />
        <path
            d="M90.1549 28.2241V30.8916H90.9155H91.676V29.0558V27.2042L93.4709 29.0558L95.2507 30.8916H96.1329H96.9999V28.2241V25.5567H96.2394H95.4788V27.2042C95.4788 28.1143 95.4332 28.8518 95.3571 28.8518C95.2963 28.8518 94.5357 28.1143 93.6535 27.2042L92.0563 25.5567H91.1132H90.1549V28.2241Z"
            fill="currentColor"
        />
        <path
            d="M33.2871 25.5437H36.3692C36.8726 25.5437 37.2425 25.6595 37.4788 25.8911C37.7151 26.1176 37.8332 26.4651 37.8332 26.9335C37.8332 27.2423 37.7664 27.4946 37.6329 27.6902C37.4993 27.8806 37.3349 28.0144 37.1397 28.0916C37.5558 28.2049 37.8383 28.4828 37.9873 28.9255C38.0387 29.0851 38.0644 29.2704 38.0644 29.4814C38.0644 29.9859 37.9462 30.3565 37.7099 30.5933C37.4736 30.83 37.1038 30.9484 36.6004 30.9484H33.2871V25.5437ZM36.6774 29.2498C36.6774 28.941 36.5233 28.7865 36.2151 28.7865H34.6741V29.8675H36.2151C36.5233 29.8675 36.6774 29.7131 36.6774 29.4042V29.2498ZM36.4463 27.0879C36.4463 26.7791 36.2922 26.6246 35.9839 26.6246L34.6741 26.6697V27.6902L35.9839 27.7056C36.2922 27.7056 36.4463 27.5512 36.4463 27.2423V27.0879Z"
            fill="currentColor"
        />
        <path
            d="M38.2218 25.5437H39.6858L40.9571 28.1689L42.2285 25.5437H43.6925L41.6506 29.52V30.9484H40.2637V29.52L38.2218 25.5437Z"
            fill="currentColor"
        />
    </svg>
</template>

<script setup lang="ts"></script>
